import React, { useEffect, useRef } from "react";
import HeroSection from "./_components/HeroSection";
import AllService from "./_components/AllService";
import NavBar from "../_components/home/_components/NavBar";

function Services() {
  const heroRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  const scrollToSection = (section) => {
    switch (section) {
      case "HeroSection":
        heroRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <NavBar onTabClick={scrollToSection} />
      <div ref={heroRef}>
        <HeroSection />
      </div>
      <AllService />
    </>
  );
}

export default Services;

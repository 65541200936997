import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import logo from "../../../../assests/logo.svg";
import image1 from "../../../../assests/img1.png";
import image2 from "../../../../assests/img2.png";
import image3 from "../../../../assests/img3.png";
import image4 from "../../../../assests/img4.png";
import image5 from "../../../../assests/img5.png";
import image6 from "../../../../assests/img6.png";
import image1H from "../../../../assests/img1H.png";
import image2H from "../../../../assests/img2H.png";
import image3H from "../../../../assests/img3H.png";
import image4H from "../../../../assests/img4H.png";
import image5H from "../../../../assests/img5H.png";
import image6H from "../../../../assests/img6H.png";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const services = [
  {
    id: 1,
    title: "Life Changing Remedies",
    image: image1,
    hoverImage: image1H,
  },
  { id: 2, title: "Anxiety & Depression", image: image2, hoverImage: image2H },
  { id: 3, title: "Negative Energy", image: image3, hoverImage: image3H },
  { id: 4, title: "Black Magic Removal", image: image4, hoverImage: image4H },
  { id: 5, title: "Health Problem", image: image5, hoverImage: image5H },
  { id: 6, title: "Reiki Energy", image: image6, hoverImage: image6H },
];

function ServicesCard() {
  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleService = () => {
    navigate("/servicess");
  };
  const customIndexes = ["01", "06", "03", "04", "05", "02"];

  return (
    <Box
      sx={{
        backgroundColor: "#FCFFF5",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        pt: { md: 13, sm: 13, xs: 7 },
        pb: { md: 12, sm: 12, xs: 7 },
        width: "100%",
      }}
    >
      <Box sx={{ maxWidth: { xl: 740, lg: 740, xs: "90%" } }}>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            gap: "10px",
            padding: { sm: "10px", xs: "6px" },
            borderRadius: "30px",
            justifyContent: "center",
            border: "2px solid #73A812",
            width: "120px",
            margin: "auto",
            height: "100%",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: "17px", height: "19px" }}
          />
          <Typography sx={{ fontSize: "14px" }}>Services</Typography>
        </Box>

        <Typography
          sx={{
            fontSize: {
              xl: "56px",
              lg: "56px",
              md: "55px",
              sm: "33px",
              xs: "26px",
            },
            mt: 1,
            color: "#18191F",
            lineHeight: 1.1,
          }}
        >
          What <span style={{ color: "#73A812" }}>Services</span> Kaneez Fatimah
          Ji Offers
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontSize: {
              xl: "18px",
              lg: "18px",
              md: "16px",
              sm: "16px",
              xs: "16px",
            },
            color: "#18191F",
          }}
        >
          Kaneez Ji is a reliable expert who has solved every sort of life issue
          related to Love Problem Solution, Black Magic Removal, Negative
          Energy, Anxiety / Depression, Evil Eye, Bad Luck Removal, Health
          Problems, Aura Cleansing, Business Problems, Family Issues, and
          Addiction Problems, etc. Kaneez Ji has a one-stop solution for all
          your problems.
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          display: { sm: "flex", xs: "none" },
          justifyContent: "center",
          width: { sm: "90%", xs: "96%" },
          margin: "auto",
          marginTop: { sm: 5, xs: 2 },
          marginBottom: 5,
        }}
      >
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={service.id}>
            <Box
              sx={{
                height: { sm: "270px", xs: "240px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid #B0B0B0",
                borderRadius: "30px",
                cursor: "pointer",
                p: 2,
                textAlign: "start",
                backgroundColor: hoveredIndex === index ? "#73A812" : "#FFFFFF",
                color: hoveredIndex === index ? "#FFFFFF" : "inherit",
                transition: "all 0.3s ease",
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <Typography
                sx={{
                  padding: "10px",
                  transition: "color 0.3s ease",
                  color: "#27343F80",
                }}
              >
                [ {customIndexes[index]} ]
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <img
                  src={
                    hoveredIndex === index ? service.hoverImage : service.image
                  }
                  alt={`service-${service.id}`}
                  style={{ maxWidth: "100px", transition: "all 0.3s ease" }}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: "25px",
                  textAlign: "start",
                  color: hoveredIndex === index ? "#FFFFFF" : "inherit",
                  transition: "color 0.3s ease",
                }}
              >
                {service.title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        sx={{
          display: { sm: "none", xs: "flex" },
          justifyContent: "center",
          width: { sm: "90%", xs: "90%" },
          margin: "auto",
          marginTop: { sm: 5, xs: 2 },
          marginBottom: 5,
        }}
      >
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={service.id} sx={{marginBottom : 2}}> 
            <Box
              sx={{
                height: { sm: "270px", xs: "240px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid #B0B0B0",
                borderRadius: "30px",
                cursor: "pointer",
                p: 2,
                textAlign: "start",
                backgroundColor: hoveredIndex === index ? "#73A812" : "#FFFFFF",
                color: hoveredIndex === index ? "#FFFFFF" : "inherit",
                transition: "all 0.3s ease",
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <Typography
                sx={{
                  padding: "10px",
                  transition: "color 0.3s ease",
                  color: "#27343F80",
                }}
              >
                [ {customIndexes[index]} ]
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <img
                  src={
                    hoveredIndex === index ? service.hoverImage : service.image
                  }
                  alt={`service-${service.id}`}
                  style={{ maxWidth: "100px", transition: "all 0.3s ease" }}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: "25px",
                  textAlign: "start",
                  color: hoveredIndex === index ? "#FFFFFF" : "inherit",
                  transition: "color 0.3s ease",
                }}
              >
                {service.title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Button
        sx={{
          width: { sm: "230px", xs: "85%" },
          height: "50px",
          textTransform: "capitalize",
          backgroundColor: "#212121",
          color: "#FFFFFF",
          borderRadius: "4px",
          "&:hover": {
            backgroundColor: "#414141",
          },
        }}
        onClick={handleService}
      >
        View All Services
      </Button>
    </Box>
  );
}

export default ServicesCard;

import React from "react";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
  List,
  Typography,
  Divider,
} from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import logo from "../../../../assests/logo.svg";
import tiktok from "../../../../assests/tiktok.png";

const Footer = () => {
  return (
    <Box
      sx={{
        background: "black",
        position: "relative",
        padding: { sm: "50px 0px 0px 10px", xs: "40px 20px 10px 20px" },
      }}
    >
      <Grid container spacing={4} justifyContent="space-around">
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              gap: { sm: "20px", xs: "10px" },
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ width: "38px", height: "43px" }}
            />
            <Typography
              sx={{
                fontSize: {
                  xl: "36px",
                  lg: "36px",
                  md: "30px",
                  sm: "26px",
                  xs: "26px",
                },
                color: "#FFFFFF",
              }}
            >
              Spiritual Healer
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize: {
                xl: "18px",
                lg: "18px",
                md: "16px",
                sm: "14px",
                xs: "14px",
              },
              mt: 3,
            }}
          >
            Kaneez Ji is a highly qualified professional and expert in Healing.
            Her services offer fast and efficient results that you can observe
            within a few days.
          </Typography>
          <Box
            sx={{ display: "flex", gap: "10px", mt: 3, mb: { sm: 15, xs: 0 } }}
          >
            <a
              href="https://www.facebook.com/profile.php?id=100086525457077&mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton size="small">
                <FacebookRoundedIcon
                  sx={{
                    backgroundColor: "#FFFFFF",
                    padding: "8px",
                    borderRadius: "50%",
                    color: "black",
                    fontSize: "24px",
                  }}
                />
              </IconButton>
            </a>

            {/* Instagram Link */}
            <a
              href="https://www.instagram.com/shaheenmussarat505/profilecard/?igsh=MXY2M3E4ZXVkaTJyaA%3D%3D"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton size="small">
                <InstagramIcon
                  sx={{
                    backgroundColor: "#FFFFFF",
                    padding: "8px",
                    borderRadius: "50%",
                    color: "black",
                    fontSize: "24px",
                  }}
                />
              </IconButton>
            </a>

            {/* TikTok Link */}
            <a
              href="https://www.tiktok.com/@kaneez.fatimah15?_t=8qmQVzALxnb&_r=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton size="small">
                <img
                  src={tiktok}
                  alt="tiktok-icon"
                  style={{
                    backgroundColor: "#FFFFFF",
                    padding: "10px",
                    borderRadius: "50%",
                    width: "24px",
                    height: "24px",
                  }}
                />
              </IconButton>
            </a>
          </Box>
        </Grid>

        {/* Links Section */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Typography sx={{ fontSize: "18px", color: "#FFFFFF" }}>
                About Us
              </Typography>
              <List>
                <Typography
                  sx={{ lineHeight: 3, color: "gray", fontSize: "15px" }}
                >
                  Who We Are
                </Typography>
                <Typography
                  sx={{ lineHeight: 3, color: "gray", fontSize: "15px" }}
                >
                  Testimonials
                </Typography>
              </List>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography sx={{ fontSize: "18px", color: "#FFFFFF" }}>
                Resources
              </Typography>
              <List>
                <Typography
                  sx={{ lineHeight: 3, color: "gray", fontSize: "15px" }}
                >
                  Why Choose Us
                </Typography>
                <Typography
                  sx={{ lineHeight: 3, color: "gray", fontSize: "15px" }}
                >
                  Services
                </Typography>
                <Typography
                  sx={{ lineHeight: 3, color: "gray", fontSize: "15px" }}
                >
                  Appointment
                </Typography>
              </List>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography sx={{ fontSize: "18px", color: "#FFFFFF" }}>
                Contact
              </Typography>
              <List>
                <Typography
                  sx={{ lineHeight: 3, color: "gray", fontSize: "15px" }}
                >
                  WhatsApp
                </Typography>
                <Typography
                  sx={{ lineHeight: 3, color: "gray", fontSize: "15px" }}
                >
                  Email Us
                </Typography>
                <Typography
                  sx={{ lineHeight: 3, color: "gray", fontSize: "15px" }}
                >
                  Facebook
                </Typography>
              </List>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ mt: 5, mb: 15 }}>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  fullWidth
                  placeholder="Your Enter Email"
                  variant="outlined"
                  InputProps={{
                    sx: {
                      backgroundColor: "#FFFFFF",
                      height: "40px",
                      borderRadius: "0px", // Remove default border radius
                      borderTopLeftRadius: "5px", // Top-left corner radius
                      borderBottomLeftRadius: "5px",
                      "& .MuiInputBase-input::placeholder": {
                        fontSize: { xs: "12px", sm: "12px", md: "16px" }, // Adjust font size based on screen size
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#black" },
                  }}
                />
                <a
                  href="mailto:Kaneezfatima10oct@gmail.com"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      fontSize: {
                        xl: "14px",
                        lg: "14px",
                        md: "14px",
                        sm: "12px",
                        xs: "10px",
                      },
                      borderTopRightRadius: "5px", // Only top-right corner
                      borderBottomRightRadius: "5px", // Only bottom-right corner
                      borderTopLeftRadius: "0px", // Keep other corners sharp
                      borderBottomLeftRadius: "0px",
                      width: { sm: "140px", xs: "108px" },
                      height: "40px",
                      backgroundColor: "#73A812",
                      color: "#FFFFFF",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#73A812",
                      },
                    }}
                    variant="contained"
                  >
                    Send Message
                  </Button>
                </a>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Newsletter Section */}

      {/* Footer Bottom */}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: { md: "98%", sm: "97%", xs: "90%" },
        }}
      >
        <Divider color="#FFFFFF" style={{ width: "100%", margin: "auto" }} />
        <Typography
          sx={{
            textAlign: "center",
            pt: "20px",
            pb: "10px",
            color: "#FFFFFF",
            fontSize: {
              xl: "16px",
              lg: "16px",
              md: "14px",
              sm: "12px",
              xs: "10px",
            },
          }}
        >
          Spiritual Healer - All rights reserved © 2024
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;

import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import logo from "../../../../assests/logo.svg";
import image1 from "../../../../assests/profile1.png";
import image2 from "../../../../assests/profile2.png";
import image3 from "../../../../assests/fullimg1.png";
import image4 from "../../../../assests/fullimg2.png";
import image5 from "../../../../assests/profile3.png";
import image6 from "../../../../assests/profile4.png";

const services = [
  {
    id: 1,
    indexId: "01",
    title: "Farah Yousaf",
    image: image1,
    description:
      "With intuitive mastery, Kaneez Ji facilitates deep healing, offering solace and empowerment. Her sanctuary is a haven of transformation.",
  },
  {
    id: 2,
    indexId: "02",
    title: "Courtney Henry",
    image: image2,
    description:
      "Experience profound healing with Kaneez Ji gentle guidance. Each session fosters growth, clarity, and renewal in a nurturing environment.",
  },
  {
    id: 3,
    image: image3,
  },
  {
    id: 4,
    image: image4,
  },
  {
    id: 5,
    indexId: "03",
    title: "Besharat Ali",
    image: image5,
    description:
      "Kaneez Ji serene sanctuary nurtures healing, fostering profound shifts. A compassionate guide, she empowers transformation with wisdom and care.",
  },
  {
    id: 6,
    indexId: "04",
    title: "Marvin McKinney",
    image: image6,
    description:
      "Healer Kaneez Ji brings profound transformation with compassionate guidance, holistic wisdom, and empowering support.",
  },
];

function Testimonials() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundColor: "#F5FFE1",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        pt: { md: 10, sm: 16, xs: 6 },
        pb: 5,
        px: isSmallScreen ? 2 : 0,
        width: { sm: "100.4%", xs: "90vw" },
        marginLeft: { sm: 0, xs: -1 },
      }}
    >
      <Box sx={{ maxWidth: isSmallScreen ? "100%" : 600 }}>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            gap: "10px",
            padding: "10px",
            borderRadius: "30px",
            justifyContent: "center",
            border: "2px solid #73A812",
            width: "130px",
            margin: "auto",
            height: "100%",
            overflowX: "auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            display: "flex",
            gap: 1,
            paddingBottom: 1.2,
          }}
          data-aos-duration="500"
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: "17px", height: "19px" }}
          />
          <Typography sx={{ fontSize: "14px" }}>Testimonials</Typography>
        </Box>

        <Typography sx={{ fontSize: isSmallScreen ? "26px" : "54px", mt: 2 }}>
          What My <span style={{ color: "#73A812" }}>Clients</span> Says
        </Typography>
        <Box sx={{ width: isSmallScreen ? "100%" : "470px", margin: "auto" }}>
          <Typography
            sx={{ mt: 2, fontSize: { lg: "18px", md: "16px", xs: "16px" } }}
          >
            Healer Kaneez Ji is trusted by many people. Read some reviews below
            to understand what her clients think of her.
          </Typography>
        </Box>
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: "start",
          width: { sm: "90%", xs: "100%" },
          margin: "auto",
          marginTop: 5,
          marginBottom: 5,
          overflowX: isSmallScreen ? "auto" : "visible",
          flexWrap: isSmallScreen ? "nowrap" : "wrap",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {services.map((service, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            key={service.id}
            sx={{
              flex: isSmallScreen ? "0 0 auto" : "initial",
              width: isSmallScreen ? "280px" : "auto",
            }}
          >
            <Box
              sx={{
                width: "100%",
                cursor: "pointer",
                backgroundColor: "#FCFFF5",
                height: "270px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid #B0B0B0",
                borderRadius: "30px",
                overflow: "hidden",
                textAlign: "start",
                transition: "background-color 0.3s, color 0.3s",
              }}
              data-aos-duration="500"
            >
              {service.id === 3 || service.id === 4 ? (
                <img
                  src={service.image}
                  alt={`service-${service.id}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "30px",
                  }}
                />
              ) : (
                <>
                  <Box sx={{ display: "flex", gap: "10px", padding: 2 }}>
                    <Box>
                      <img
                        src={service.image}
                        alt={`service-${service.id}`}
                        style={{
                          width: "75px",
                          height: "75px",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <Typography sx={{ color: "#27343F80" }}>
                        [ {service.indexId} ]
                      </Typography>
                      <Typography sx={{ color: "#27343F" }}>
                        {service.title}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: isSmallScreen ? "14px" : "16px",
                      textAlign: "start",
                      lineHeight: 1.5,
                      color: "#27343FCC",
                      mt: 1,
                      overflow: "hidden",
                      padding: 2,
                    }}
                  >
                    {service.description}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{ display: { lg: "none", md: "none", sm: "none", xs: "block" } }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              cursor: "pointer",
              background: "#73A812",
            }}
          />

          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              cursor: "pointer",
              background: "#73A812",
            }}
          />
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              cursor: "pointer",
              background: "gray",
            }}
          />
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              cursor: "pointer",
              background: "gray",
            }}
          />
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              cursor: "pointer",
              background: "gray",
            }}
          />
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              cursor: "pointer",
              background: "gray",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Testimonials;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import contactImg from "../../../../assests/contact.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [message, setMessage] = useState("");

  const isFormValid = () => {
    return (
      name.trim() &&
      number.trim() &&
      date.trim() &&
      time.trim() &&
      message.trim()
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      // Navigate to WhatsApp with a pre-filled message
      const messageText = `Name: ${name}\nNumber: ${number}\nDate: ${date}\nTime: ${time}\nMessage: ${message}`;
      const encodedMessage = encodeURIComponent(messageText);
      window.open(
        `https://wa.me/923063537555?text=${encodedMessage}`,
        "_blank"
      );
    } else {
      toast.error("Please fill all the required fields!", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      {" "}
      <ToastContainer position="top-right" />
      <Box sx={{ pt: { md: 10, xs: 5 }, backgroundColor: "#FCFFF5", pb: 5, width : "100.5%" }}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          {/* Contact Image Section */}
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box
              sx={{
                display: { xs: "block", md: "block" },
                height: "90%",
                overflow: "hidden",
              }}
            >
              <Box
                component="img"
                src={contactImg}
                alt="contact"
                sx={{
                  width: { xs: "100%", sm: "100%" },
                  height: { xs: "60vh", sm: "100vh" },
                }}
              />
            </Box>
          </Grid>

          {/* Contact Form Section */}
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                padding: { xl: 3, lg: 3, md: 3, sm: 3, xs: 1 },
                backgroundColor: "#F5FFE1",
                height: "90%",
                borderRadius: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xl: "36px", lg: "32px", md: "28px", xs: "24px" },
                  fontWeight: 500,
                  textAlign: "start",
                  color: "#27343F",
                }}
              >
                Share Your <span style={{ color: "#73A812" }}>Problem</span>
              </Typography>
              <Typography
                sx={{
                  fontSize: { xl: "16px", lg: "16px", md: "14px", xs: "12px" },
                  mb: 3,
                  mt: 1,
                  color: "#27343FCC",
                }}
              >
                We Will Contact You Within 48 Hours.
              </Typography>

              {/* Form Fields */}
              <Box component="form" onSubmit={handleSubmit}>
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ mb: 0.5, color: "#27343FCC" }}>
                    Name*
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Your Name"
                    InputProps={{ style: { height: "50px" } }}
                  />
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ mb: 0.5, color: "#27343FCC" }}>
                    WhatsApp Number*
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="Enter Your Number"
                    type="tel"
                    InputProps={{ style: { height: "50px" } }}
                  />
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography sx={{ mb: 0.5, color: "#27343FCC" }}>
                      Date*
                    </Typography>
                    <TextField
                      type="date"
                      fullWidth
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      InputProps={{ style: { height: "50px" } }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ mb: 0.5, color: "#27343FCC" }}>
                      Time*
                    </Typography>
                    <TextField
                      type="time"
                      fullWidth
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      InputProps={{ style: { height: "50px" } }}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2, mt: 2 }}>
                  <Typography sx={{ mb: 0.5, color: "#27343FCC" }}>
                    Message*
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Your message"
                    multiline
                    rows={4}
                  />
                </Box>

                {/* Submit Button */}
                <Button
                  type="submit"
                  fullWidth
                  sx={{
                    backgroundColor: "#73A812",
                    color: "#FFFFFF",
                    height: "50px",
                    borderRadius: "6px",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#5c8a0a" },
                  }}
                >
                  Book an Appointment
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Contact;

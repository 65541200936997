import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider, Grid } from "@mui/material";
import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../../../../assests/logo.svg";
import shape from "../../../../assests/Shape.png";

function Portfolio() {
  const [counterOn, setCounterOn] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCounterOn(true);
          } else {
            setCounterOn(false);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        height: { sm: "85vh", xs: "65vh" },
        pt: { sm: 12, xs: 7 },
        backgroundColor: "#FCFFF5",
        width: {sm :"99vw", xs :"100vw"},
        marginLeft: { sm: -1.5, xs: -1 },
      }}
    >
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        <img
          src={shape}
          alt="shape"
          style={{
            width: "250px",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 2,
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",

          }}
        >
          <Box
            sx={{
              width: { md: "740px", xs: "90%" },
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  gap: "10px",
                  padding: { sm: "10px", xs: "5px" },
                  borderRadius: "30px",
                  justifyContent: "center",
                  border: "2px solid #73A812",
                  width: { sm: "170px", xs: "130px" },
                  margin: "auto",
                }}
              >
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: "17px", height: "19px" }}
                />
                <Typography
                  sx={{
                    fontSize: {
                      xl: "14px",
                      lg: "14px",
                      md: "14px",
                      sm: "14px",
                      xs: "11px",
                    },
                    mt: { sm: 0, xs: "4px" },
                  }}
                >
                  Why Choose Me
                </Typography>
              </Box>
              <Typography
                sx={{ fontSize: { lg: "56px", md: "50px", xs: "26px" }, mt: 2 }}
              >
                Why Should You Choose Healer{" "}
                <span style={{ color: "#73A812" }}>Kaneez Fatimah Ji</span>
              </Typography>
            </Box>
          </Box>

          <Box
            ref={targetRef}
            sx={{
              width: { xl: "90%", lg: "90%", md: "100%" },
              margin: "auto",
              height: {
                xl: "147px",
                lg: "180px",
                md: "150px",
                sm: "100%",
                xs: "100%",
              },
              padding: 2,
            }}
          >
            {/* Grid Layout */}
            <Grid container spacing={2}>
              {[
                {
                  end: 24,
                  title: "Year Experience",
                  desc: "Over 24 years of combined experience in Reiki practice.",
                },
                {
                  end: 15000,
                  title: "Case Solved",
                  desc: "Successfully addressed stress, anxiety, & emotional blockages.",
                },
                {
                  end: 12,
                  suffix: "k",
                  title: "Trusted Clients",
                  desc: "Join community of satisfied clients who recommend my services.",
                },
                {
                  end: 14,
                  title: "Countries Followers",
                  desc: "My Spiritual Healing services have connected individuals in 14 countries.",
                },
              ].map((item, index) => (
                <Grid item xs={6} sm={6} md={3} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      textAlign: { xs: "center", sm: "center" },
                      mt: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xl: "40px",
                          lg: "40px",
                          md: "34px",
                          sm: "34px",
                          xs: "30px",
                        },
                        fontWeight: 600,
                        color: "#73A812",
                      }}
                    >
                      {counterOn && (
                        <CountUp
                          start={0}
                          end={item.end}
                          duration={1.5}
                          delay={0}
                        />
                      )}
                      {item.suffix || ""}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xl: "18px",
                          lg: "18px",
                          md: "16px",
                          sm: "14px",
                          xs: "13px",
                        },
                        fontWeight: 600,
                        color: "#171717",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        width: {
                          xl: "100px",
                          lg: "200px",
                          md: "200px",
                          xs: "140px",
                        },
                        fontSize: {
                          xl: "16px",
                          lg: "14px",
                          md: "14px",
                          sm: "12px",
                          xs: "10px",
                        },
                        color: "#475569",
                        mt: 1,
                        textAlign: "start",
                      }}
                    >
                      {item.desc}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Portfolio;

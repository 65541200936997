import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import logo from "../../../../assests/logo.svg";
import image1 from "../../../../assests/product1.png";
import image2 from "../../../../assests/product2.png";
import image3 from "../../../../assests/product3.png";
import image4 from "../../../../assests/product4.png";
import whatsapp from "../../../../assests/Group.png";

const products = [
  { id: 1, title: "Amethyst", image: image1, reviews: 580 },
  { id: 2, title: "Mother of Pearl Bracelet", image: image2, reviews: 650 },
  { id: 3, title: "Green Aventurine", image: image3, reviews: 520 },
  { id: 4, title: "Red Jasper Bracelet", image: image4, reviews: 350 },
];

function ProductCard({ product }) {
  return (
    <Box
      sx={{
        width: { xs: "90%" },
        borderRadius: 4,
        padding: 2,
        position: "relative",
        backgroundColor: "#fff",
        textAlign: "center",
        height: { sm: "370px", xs: "360px" },
        border: "1px solid #D5D5D5",
        marginLeft: { sm: 0, xs: -1 },
      }}
    >
      <CardMedia
        component="img"
        height="280"
        image={product.image}
        alt={product.title}
        sx={{ borderRadius: 2 }}
      />
      <IconButton
        sx={{ position: "absolute", top: 15, right: 12, color: "#73A812" }}
      >
        <FavoriteBorderIcon />
      </IconButton>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography sx={{ textAlign: "start" }}>{product.title}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="text.secondary">
              {product.reviews} Reviews
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "cener", mt: 1 }}>
              {Array(5)
                .fill()
                .map((_, index) => (
                  <StarIcon
                    key={index}
                    sx={{ color: "#FFD700", fontSize: 20 }}
                  />
                ))}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Box>
  );
}

function OurProducts() {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Box
      sx={{
        backgroundColor: "#FCFFF5",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        pt: { md: 10, sm: 8, xs: 7 },
        pb: { md: 5, sm: 5, xs: 3 },
        px: 2.8,
        width: { sm: "97%", xs: "88vw" },
        marginLeft: { sm: 0, xs: -2 },
      }}
    >
      {/* Header Section */}
      <Box>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            gap: 1,
            padding: 1,
            borderRadius: "30px",
            justifyContent: "center",
            border: "2px solid #73A812",
            width: "fit-content",
            margin: "auto",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: "17px", height: "19px" }}
          />
          <Typography sx={{ fontSize: "14px" }}>Our Products</Typography>
        </Box>

        <Typography
          variant="h3"
          sx={{ fontSize: { xs: "32px", md: "48px" }, mt: 2, mb: 2 }}
        >
          Explore Our <span style={{ color: "#73A812" }}>Handmade</span> Crafts
        </Typography>
        <Typography
          sx={{
            maxWidth: "400px",
            margin: "auto",
            fontSize: { xs: "16px", md: "18px" },
          }}
        >
          Discover unique, handcrafted items that inspire creativity and joy.
        </Typography>
      </Box>

      {/* Product Cards Section */}
      <Grid
        spacing={2}
        container
        sx={{ mt: 4, justifyContent: "center", maxWidth: "1200px", mx: "auto" }}
      >
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>

      {/* Navigation Dots */}
      {/* <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
        {products.map((_, index) => (
          <Box
            key={index}
            onClick={() => setCurrentIndex(index)}
            sx={{
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor: currentIndex === index ? "#73A812" : "#A6A6A6",
              cursor: "pointer",
            }}
          />
        ))}
      </Box> */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <a
          href="https://wa.me/923063537555"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button
            sx={{
              color: "#FFFFFF",
              backgroundColor: "#73A812",
              textTransform: "capitalize",
              height: {
                xl: "50px",
                lg: "50px",
                md: "50px",
                sm: "40px",
                xs: "40px",
              },
              width: {
                xl: "245px",
                lg: "245px",
                md: "200px",
                sm: "150px",
                xs: "87vw",
              },
              marginLeft : {sm : 0, xs : 2},
              mt: { xl: 5, lg: 5, md: 4, sm: 3, xs: 5 },
              mb: 3,
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#73A812",
              },
              fontSize: {
                xl: "18px",
                lg: "15px",
                md: "14px",
                sm: "12px",
                xs: "14px",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={whatsapp}
              alt="whatsapp-icon"
              style={{ color: "#000000", marginRight: 10, width: "18px" }}
            />
            WhatsApp
          </Button>
        </a>
      </Box>
    </Box>
  );
}

export default OurProducts;

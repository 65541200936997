import React, { useEffect, useRef } from "react";
import HeroSection from "./_components/HeroSection";
import Portfolio from "./_components/Portfolio";
import About from "./_components/About";
import ServicesCard from "./_components/ServicesCard";
import Testimonials from "./_components/Testimonials";
import OurProducts from "./_components/OurProducts";
import NavBar from "./_components/NavBar";

function Home() {
  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const testimonialsRef = useRef(null);
  const productsRef = useRef(null);

  const scrollToSection = (section) => {
    switch (section) {
      case "Home":
        heroRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "About":
        aboutRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "Services":
        servicesRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "Testimonials":
        testimonialsRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "Our Product":
        productsRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <NavBar onTabClick={scrollToSection} />
      <div ref={heroRef}>
        <HeroSection />
      </div>
      <Portfolio />
      <div ref={aboutRef}>
        <About />
      </div>
      <div ref={servicesRef}>
        <ServicesCard />
      </div>
      <div ref={testimonialsRef}>
        <Testimonials />
      </div>
      <div ref={productsRef}>
        <OurProducts />
      </div>
    </>
  );
}

export default Home;

import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import WhatsAppIcon from "../../../../assests/Group.png";
import EmailIcon from "../../../../assests/email.png";

function Timezone() {
  const commonStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  };

  return (
    <Box>
      {/* Main Container */}
      <Box
        sx={{
          backgroundColor: "#73A812",
          width: {sm :"99vw", xs :"100vw"},
          marginLeft: { sm: -1.5, xs: -1 },
        }}
      >
        {/* Inner Content Box */}
        <Box
          sx={{
            width: { sm: "90%", xs: "95%" },
            margin: "auto",
            display: "flex",
            justifyContent: { xs: "space-between", md: "start", lg: "start" },
            flexDirection: { xs: "row", md: "row" }, // Responsive column for small screens
            gap: { xs: "3px", md: "80px" },
            padding: { xs: "15px 0px 5px 0px", md: "10px" },
          }}
        >
          {/* WhatsApp Section */}
          <Box sx={commonStyle}>
            <a
              href="https://wa.me/923063537555"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
              }}
            >
              <Box
                component="img"
                src={WhatsAppIcon}
                alt="whatsapp-icon"
                sx={{
                  width: { xs: "14px", sm: "20px" },
                  height: { xs: "14px", sm: "20px" },
                }}
              />
              <Typography
                sx={{
                  ml: 1,
                  color: "#FFFFFF",
                  fontSize: { xs: "10px", sm: "14px", md: "16px" }, // Font size responsive
                }}
              >
                +92 306 3537555
              </Typography>
            </a>
          </Box>

          {/* Email Section */}
          <Box sx={commonStyle}>
            <a
              href="mailto:Kaneezfatima10oct@gmail.com"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
              }}
            >
              <Box
                component="img"
                src={EmailIcon}
                alt="email-icon"
                sx={{
                  width: { xs: "14px", sm: "20px" },
                  height: { xs: "14px", sm: "20px" },
                }}
              />
              <Typography
                sx={{
                  ml: 1,
                  color: "#FFFFFF",
                  fontSize: { xs: "10px", sm: "14px", md: "16px" }, // Font size responsive
                }}
              >
                Kaneezfatima10oct@gmail.com
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>

      {/* Divider */}
      <Divider
        sx={{ backgroundColor: "#D9DBE1", width: "100%", margin: "auto" }}
      />
    </Box>
  );
}

export default Timezone;

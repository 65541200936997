import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../../../../assests/logo.svg";
import WhatsAppIcon from "../../../../assests/Group.png";

const boxStyle = {
  position: "relative",
  width: { sm: "99vw", xs: "100vw" },
  height: "100vh",
  overflow: "hidden",
  display: "flex",
  marginLeft: { sm: -1.5, xs: -1 },
  flexDirection: { xs: "column", sm: "row" },
};

function HeroSection() {
  return (
    <Box sx={boxStyle}>
      <video
        autoPlay
        loop
        muted
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100vw",
          height: "100%",
          transform: "translate(-50%, -50%)",
          objectFit: "cover",
        }}
      >
        <source src="herosection.mp4" type="video/mp4" />
      </video>

      <Box
        sx={{
          marginLeft: {
            xl: "80px",
            lg: "80px",
            md: "70px",
            sm: "0px",
            xs: "0px",
          },
          backgroundColor: "rgba(238, 255, 206, 0.8)",
          width: { xs: "100%", sm: "38%" },
          height: { xs: "40%", sm: "100%" },
          pt: { xl: 20, lg: 18, md: 10, sm: 10, xs: 6 },
          zIndex: 1.1,
          position: "relative",
          padding: { xs: "20px", sm: "40px" },
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "flex-start", sm: "flex-start" },
          textAlign: { xs: "flex-start", sm: "flex-start" },
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            padding: { sm: "10px", xs: "7px" },
            borderRadius: "30px",
            width: { xs: "150px", sm: "200px" },
            justifyContent: { xs: "start", sm: "center" },
            marginBottom: { xs: 2, sm: 4 },
            mt : {xs : 3, sm : 0},
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: "17px", height: "19px" }}
          />
          <Typography
            sx={{
              fontSize: {
                xl: "14px",
                lg: "14px",
                md: "12px",
                sm: "12px",
                xs: "10px",
              },
            }}
          >
            Kaneez Ji Healing Hype
          </Typography>
        </Box>

        {/* Heading Text */}
        <Typography
          sx={{
            width: { xs: "90%", sm: "100%" },
            color: "#000000",
            fontSize: {
              xl: "64px",
              lg: "58px",
              md: "55px",
              sm: "36px",
              xs: "32px",
            },
            fontWeight: 500,
            lineHeight: 1.1,
          }}
        >
          Welcome to Your <span style={{ color: "#73A812" }}>Healing</span>{" "}
          Journey
        </Typography>

        {/* Description Text */}
        <Typography
          sx={{
            width: { xs: "85%", sm: "85%" },
            color: "#000000",
            fontSize: {
              xl: "20px",
              lg: "20px",
              md: "16px",
              sm: "16px",
              xs: "16px",
            },
            fontFamily: "Lato !important",
            mt: { sm: 2, xs: 2 },
          }}
        >
          Experience the uplifting power of Spiritual Healing and restore your
          balance.
        </Typography>

        {/* WhatsApp Button */}
        <a
          href="https://wa.me/923063537555"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button
            sx={{
              color: "#FFFFFF",
              backgroundColor: "#73A812",
              textTransform: "capitalize",
              height: {
                xl: "50px",
                lg: "50px",
                md: "50px",
                sm: "40px",
                xs: "35px",
              },
              width: {
                xl: "245px",
                lg: "245px",
                md: "200px",
                sm: "150px",
                xs: "88vw",
              },
              mt: { xl: 5, lg: 5, md: 4, sm: 3, xs: 3 },
              mb: 3,
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#73A812",
              },
              fontSize: {
                xl: "18px",
                lg: "15px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={WhatsAppIcon}
              alt="whatsapp-icon"
              sx={{
                marginRight: 1,
                width: { xs: "16px", sm: "20px" },
                height: { xs: "16px", sm: "20px" },
              }}
            />
            WhatsApp
          </Button>
        </a>
      </Box>
    </Box>
  );
}

export default HeroSection;

import React from "react";
import logo from "../../../../assests/logo.svg";
import about from "../../../../assests/about.png";
import whatsapp from "../../../../assests/Group.png";
import { Typography, Box, Button } from "@mui/material";

function About() {
  return (
    <Box
      sx={{
        backgroundColor: "#F5FFE1",
        padding: "16px",
        display: "flex",
        justifyContent: "space-around",
        flexDirection: {
          xl: "row",
          lg: "row",
          md: "row",
          sm: "column",
          xs: "column",
        },
        width: { sm: "98%", xs: "90vw" },
        marginLeft: { sm: 0, xs: -0.9 },
      }}
    >
      <Box
        sx={{
          width: { lg: "40%", md: "50%", xs: "100%" },
          height: "100%",
          pt: { xl: 10, lg: 10, md: 10, sm: 10, xs: 5 },
          zIndex: 2,
          position: "relative",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            gap: "10px",
            padding: { sm: "10px", xs: "7px" },
            borderRadius: "30px",
            width: { sm: "150px", xs: "130px" },
            justifyContent: "center",
            border: "2px solid #73A812",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: "17px", height: "19px" }}
          />
          <Typography
            sx={{
              fontSize: {
                xl: "14px",
                lg: "14px",
                md: "12px",
                sm: "12px",
                xs: "12px",
              },
            }}
          >
            About Kaneez ji
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "#000000",
            fontSize: {
              xl: "56px",
              lg: "56px",
              md: "55px",
              sm: "33px",
              xs: "26px",
            },
            fontWeight: 400,
            color: "#18191F",
            mt: 2,
            lineHeight: 1.2,
          }}
        >
          Consult <apan style={{ color: "#73A812" }}>Kaneez Ji</apan> Today for
          Tailored Solutions
        </Typography>
        <Box
          sx={{
            color: "#000000",
            fontSize: {
              xl: "18px",
              lg: "18px",
              md: "16px",
              sm: "12px",
              xs: "8px",
            },
          }}
        >
          <Typography
            sx={{
              width: { xl: "500px", lg: "500px", sm: "100%" },
              color: "#000000",
              fontSize: {
                xl: "18px",
                lg: "18px",
                md: "16px",
                sm: "16px",
                xs: "16px",
              },
              mt: 1.2,
            }}
          >
            Kaneez Ji is a leading spiritual healer renowned for addressing a
            wide range of life issues, including love problems, black magic
            removal, negative energy, anxiety, evil `eye, bad luck, health
            concerns, aura cleansing, business challenges, family issues, and
            addiction.
          </Typography>
          <Typography
            sx={{
              mt: 2,
              fontSize: {
                xl: "18px",
                lg: "18px",
                md: "16px",
                sm: "16px",
                xs: "16px",
              },
            }}
          >
            Book an appointment today to start your journey toward peace and
            happiness!
          </Typography>
        </Box>
        <a
          href="https://wa.me/923063537555"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button
            sx={{
              color: "#FFFFFF",
              backgroundColor: "#73A812",
              textTransform: "capitalize",
              height: {
                xl: "50px",
                lg: "50px",
                md: "50px",
                sm: "40px",
                xs: "40px",
              },
              width: {
                xl: "245px",
                lg: "245px",
                md: "200px",
                sm: "150px",
                xs: "89vw",
              },
              mt: { xl: 5, lg: 5, md: 4, sm: 3, xs: 2 },
              mb: { sm: 10, xs: 6 },
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#73A812",
              },
              fontSize: {
                xl: "18px",
                lg: "15px",
                md: "14px",
                sm: "10px",
                xs: "14px",
              },
            }}
          >
            <img
              src={whatsapp}
              alt="whatsapp-icon"
              style={{ color: "#000000", marginRight: 10 }}
            />
            WhatsApp Us
          </Button>
        </a>
      </Box>
      <Box>
        <img src={about} alt="logo" style={{ width: "100%", height: "100%" }} />
      </Box>
    </Box>
  );
}

export default About;

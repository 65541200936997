import React from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Drawer,
  IconButton,
  Divider,
  useMediaQuery,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import LogoIcon from "../../../../assests/logo.svg";
import menuIcon from "../../../../assests/menu.png";
import whatsapp from "../../../../assests/whtap-color.png";

function NavBar({ onTabClick }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const tabs = [
    { label: "Home", path: "/" },
    { label: "About", path: "/" },
    { label: "Services", path: "/" },
    { label: "Testimonials", path: "/" },
    { label: "Our Product", path: "/" },
  ];

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleTabClick = (section) => {
    onTabClick(section);
    closeDrawer();
  };

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  const renderTabs = () => (
    <Tabs
      orientation={isMobile ? "vertical" : "horizontal"}
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: isMobile ? "flex-start" : "center", // Align to start in mobile view
        textAlign: isMobile ? "start" : "center",
        justifyContent: isMobile ? "flex-start" : "center", // Add this line
        paddingLeft: isMobile ? 0 : 0, // Add some padding for better spacing
      }}
    >
      {tabs.map((tab, index) => (
        <React.Fragment key={index}>
          <Tab
            component={Link}
            to={tab.path}
            label={tab.label}
            onClick={() => handleTabClick(tab.label)}
            sx={{
              backgroundColor: isMobile ? "rgba(0, 0, 0, 0)" : "#EEFFCE",
              textTransform: "capitalize",
              alignItems: "flex-start",
              color: isMobile ? "#FFFFFF" : "#2E2E2E",
              zIndex: 2,
              transition: "color 0.3s, background-color 0.3s",
              "&:hover": {
                borderRadius: "5px",
                fontWeight: 600,
                color: isMobile ? "#FFFFF7 !important" : "#73A812",
              },
            }}
          />
          <Divider
            sx={{ width: "100%", display: { sm: "none", xs: "block" } }}
          />
        </React.Fragment>
      ))}
    </Tabs>
  );

  const renderDrawerButtons = () => (
    <Box sx={{ width: "90%", margin: "auto", mt: 5 }}>
      <a
        href="https://wa.me/923063537555"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Button
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#212121",
            color: "#FFFFFF",
            padding: "8px 16px",
            borderRadius: "5px",
            width: "100%",
            height: "48px",
            "&:hover": {
              backgroundColor: "#414141",
            },
          }}
        >
          Book An Appointment
        </Button>
      </a>

      <a
        href="https://wa.me/923063537555"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Button
          sx={{
            color: "#73A812",
            backgroundColor: "#FFFFFF",
            textTransform: "capitalize",
            width: "100%",
            height: "48px",
            mt: 2,
            borderRadius: "5px",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
            fontSize: "16px",
          }}
        >
          <img
            src={whatsapp}
            alt="whatsapp-icon"
            style={{ marginRight: 10, width: "23px", height: "23px" }}
          />
          WhatsApp
        </Button>
      </a>
    </Box>
  );

  const renderDrawer = () => (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={closeDrawer}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          backgroundColor: "#73A812",
        },
      }}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <IconButton sx={{ ml: "auto" }} onClick={closeDrawer}>
        <CloseIcon />
      </IconButton>
      {renderTabs()}
      {renderDrawerButtons()}
    </Drawer>
  );

  return (
    <Box
      sx={{
        width: { sm: "90%", xs: "100%" },
        margin: "auto",
        padding: { sm: "0 16px", xs: "0 0px" },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "white",
        height: "70px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <img src={LogoIcon} alt="logo" width={36} height={36} />
        <Typography sx={{ color: "#414141", fontSize: 22 }}>
          Spiritual Healer
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        {isMobile ? (
          <>
            <IconButton onClick={openDrawer}>
              <img
                src={menuIcon}
                alt="menu-icon"
                style={{
                  width: { sm: "7px", md: "20px" },
                  height: { sm: "7px", md: "20px" },
                }}
              />
            </IconButton>
            {renderDrawer()}
          </>
        ) : (
          renderTabs()
        )}
      </Box>

      {!isMobile && (
        <a
          href="https://wa.me/923063537555"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#212121",
                color: "#FFFFFF",
                padding: "8px 16px",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "#414141",
                },
              }}
            >
              Book An Appointment
            </Button>
          </Box>
        </a>
      )}
    </Box>
  );
}

export default NavBar;

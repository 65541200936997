import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Home from "./app/_components/home/page";
import NavBar from "./app/_components/home/_components/NavBar";
import Timezone from "./app/_components/home/_components/Timezone";
import Footer from "./app/_components/home/_components/Footer";
import Services from "./app/servicess/page";
import Contact from "./app/_components/home/_components/Contact";

const AppRoutes = () => {
  return useRoutes([
    { path: "/", element: <Home /> },
    { path: "/servicess", element: <Services /> },
  ]);
};

function App() {
  return (
    <Router>
      <Timezone />
      {/* <NavBar /> */}
      <AppRoutes />
      <Contact />
      <Footer />
    </Router>
  );
}

export default App;

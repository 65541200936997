import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../../../assests/logo.svg";
import whatsapp from "../../../assests/Group.png";
import HeroSec from "../../../assests/HeroSec.jpg";

const boxStyle = {
  position: "relative",
  width: {sm :"99vw", xs : "100vw"}, // Ensure full viewport width
  height: "100vh", // Ensure full viewport height
  overflow: "hidden", // Prevent any overflow
  display: "flex",
  marginLeft: { sm: -1.5, xs: -1 },
  justifyContent: "center",
  alignItems: "center",
};

function HeroSection() {
  return (
    <Box sx={boxStyle}>
      {/* Background Image */}
      <img
        src={HeroSec}
        alt="hero-section"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "relative",
        }}
      />

      {/* Transparent Card Content */}
      <Box
        sx={{
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "flex-start", md: "flex-start" },
          justifyContent: "center",
          height: "100%",
          color: "#FFFFFF",
          padding: { xs: "20px", md: "80px" },
          position: "absolute",
          bottom: { md: -50, xs: 10 },
          width: { md: "500px", xs: "100%" },
          left: 0,
          zIndex: 1,
        }}
      >
        {/* Logo Box */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            padding: "10px",
            borderRadius: "30px",
            width: { xs: "150px", md: "200px" },
            justifyContent: "center",
            marginBottom: "20px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: "17px", height: "19px" }}
          />
          <Typography
            sx={{
              fontSize: {
                xl: "14px",
                lg: "14px",
                md: "12px",
                sm: "10px",
                xs: "10px",
              },
              color: "#000000",
            }}
          >
            Kaneez Ji Healing Hype
          </Typography>
        </Box>

        {/* Heading */}
        <Typography
          sx={{
            fontSize: {
              xl: "64px",
              lg: "64px",
              md: "55px",
              sm: "46px",
              xs: "40px",
            },
            fontWeight: 600,
            lineHeight: 1.2,
            marginBottom: "20px",
          }}
        >
          <span style={{ color: "#73A812" }}>Services</span> We Provided
        </Typography>

        {/* Subheading */}
        <Typography
          sx={{
            width: { xs: "90%", md: "80%" },
            fontSize: {
              xl: "18px",
              lg: "18px",
              md: "16px",
              sm: "16px",
              xs: "16px",
            },
            marginBottom: "25px",
            fontFamily: "Lato !important",
          }}
        >
          Customized sessions designed to nurture your mind, body, and spirit.
        </Typography>

        {/* WhatsApp Button */}
        <a
          href="https://wa.me/923063537555"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: "#FFFFFF",
              backgroundColor: "#73A812",
              textTransform: "capitalize",
              height: {
                xl: "50px",
                lg: "50px",
                md: "50px",
                sm: "40px",
                xs: "40px",
              },
              width: {
                xl: "245px",
                lg: "245px",
                md: "200px",
                sm: "150px",
                xs: "88vw",
              },
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#5c8e10",
              },
              fontSize: {
                xl: "18px",
                lg: "16px",
                md: "15px",
                sm: "13px",
                xs: "12px",
              },
            }}
          >
            <img
              src={whatsapp}
              alt="whatsapp-icon"
              style={{ width: "20px", height: "20px" }}
            />
            WhatsApp
          </Button>
        </a>
      </Box>
    </Box>
  );
}

export default HeroSection;

import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import EastIcon from "@mui/icons-material/East";
import image1 from "../../../assests/service1.png";
import image2 from "../../../assests/service2.png";
import image3 from "../../../assests/service3.png";
import image4 from "../../../assests/service4.png";
import image5 from "../../../assests/service5.png";
import image6 from "../../../assests/service6.png";
import image7 from "../../../assests/service7.png";
import image8 from "../../../assests/service8.png";
import image9 from "../../../assests/service9.png";
import image10 from "../../../assests/service10.png";
import image11 from "../../../assests/service11.png";
import image12 from "../../../assests/service12.png";
import image13 from "../../../assests/service13.png";
import image14 from "../../../assests/service14.png";
import image15 from "../../../assests/service15.png";
import image16 from "../../../assests/service16.png";
import logo from "../../../assests/logo.svg";

function AllService() {
  const cardData = [
    {
      id: 1,
      imageSrc: image1,
      primaryText: "Negative Energy",
      secondaryText1:
        "Negative energy can lead to challenges in relationships, finances, and health, causing significant setbacks and hindering personal growth. Its disruptive influence can prevent individuals from achieving their goals, resulting in repeated obstacles.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 2,
      imageSrc: image2,
      primaryText: "Love Problem Solution",
      secondaryText1:
        "After a breakup, it's natural to feel a desire to reconnect with an ex-partner. Spiritual healing remedies can provide guidance and clarity, helping individuals navigate the path to reconciliation and address compatibility issues.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 3,
      imageSrc: image3,
      primaryText: "Anxiety/Depression",
      secondaryText1:
        "Depression is a widespread mental disorder that affects both psychological and physical well-being, with over 17 million people in the U.S. facing it annually. Our spiritual healing remedies offer support to help individuals overcome depression and anxiety, improving their quality of life.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 4,
      imageSrc: image4,
      primaryText: "Black Magic Removal",
      secondaryText1:
        "Black magic involves invoking evil spirits to fulfill selfish desires, often practiced by skilled magicians and recognized by various names worldwide. Our spiritual healing techniques provide powerful remedies, offering protection against its negative influences.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 5,
      imageSrc: image5,
      primaryText: "Evil Eye",
      secondaryText1:
        "The evil eye, or 'buri nazar,' is a common belief that an envious gaze can bring harm or misfortune. Our spiritual healing remedies, rooted in ancient techniques, provide protection against the negative effects of jealousy and envy.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 6,
      imageSrc: image6,
      primaryText: "Bad Luck Removal",
      secondaryText1:
        "Constant failure and disappointment can instill fear, but misfortune doesn't define one's destiny. Our expert spiritual healer offers remedies to remove bad luck and provide the protection needed to lead a fulfilling life.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 7,
      imageSrc: image7,
      primaryText: "Health Problem",
      secondaryText1:
        "Optimal health involves a harmonious functioning of all aspects of our being, significantly impacting our quality of life. While modern medicine addresses physical ailments, true healing also requires spiritual remedies to restore balance to the body, mind, and soul.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 8,
      imageSrc: image8,
      primaryText: "Psychic Reader",
      secondaryText1:
        "Psychic reading connects us with surrounding energies, offering insights into the past, present, and future. Services like crystal and tarot readings can help interpret the universe's hints and provide answers to your questions.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 9,
      imageSrc: image9,
      primaryText: "Aura Cleaning",
      secondaryText1:
        "In Sanskrit, 'chakra' means 'disk' or 'wheel,' referring to the energy centers aligned with nerve clusters and organs in the body. Our aura cleansing experts can help remove blockages in the seven primary chakras, promoting optimal physical and emotional functioning.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },

    {
      id: 10,
      imageSrc: image10,
      primaryText: "Reiki Energy",
      secondaryText1:
        "Reiki is an energy healing modality that promotes relaxation and reduces stress through gentle touch, enhancing the flow of vital life force energy. Originating from Mikao Usui in the early 1900s, Reiki is now widely used globally to support holistic healing in various healthcare settings.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 11,
      imageSrc: image11,
      primaryText: "Numerologist",
      secondaryText1:
        "Numerology services offer insights into an individual's life purpose, strengths, and challenges by analyzing their birth date. By revealing significant numbers in a person's chart, numerology serves as a guide to navigate upcoming experiences and understand life's journey.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 12,
      imageSrc: image12,
      primaryText: "Family Issues",
      secondaryText1:
        "A harmonious family is essential for individual growth, providing support and guidance through life's challenges. When conflicts and misunderstandings threaten this foundation, spiritual healing remedies can offer effective solutions to restore harmony.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 13,
      imageSrc: image13,
      primaryText: "Ilm-un-Nefs",
      secondaryText1:
        "Ilm al Nafs, or the knowledge of the self, refers to the diverse functions of the soul, including sensation, imagination, rational thinking, and intuition. Classical scholars used this term to discuss mental and spiritual health in the context of human growth and development.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 14,
      imageSrc: image14,
      primaryText: "Gem Stones",
      secondaryText1:
        "Successfully running a business requires skill and determination, but challenges can make targets feel out of reach. If you're investing effort without seeing results, spiritual healing remedies may provide the support you need to realign and succeed.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 15,
      imageSrc: image15,
      primaryText: "Life Changing Remedies",
      secondaryText1:
        "If you have business issues, marriage issues, heath issues, kids attitude problems then you can discuss with an appropriate remedy for a particular situation.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
    {
      id: 16,
      imageSrc: image16,
      primaryText: "Business Problems",
      secondaryText1:
        "Successfully running a business demands skill and determination, but challenges can make targets feel unattainable. If you're facing obstacles despite your hard work, spiritual healing remedies can provide effective solutions to help you realign and thrive.",
      booking: "Book an appointment",
      whatsappLink:
        "https://wa.me/+923063537555?text=I%20would%20like%20to%20book%20an%20appointment%20for%20Negative%20Energy",
    },
  ];

  return (
    <Box>
      <Box>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            gap: "10px",
            padding: "8px",
            borderRadius: "30px",
            justifyContent: "center",
            border: "2px solid #73A812",
            width: "120px",
            margin: "auto",
            height: "100%",
            marginTop: { md: 12, xs: 7 },
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: "17px", height: "19px" }}
          />
          <Typography sx={{ fontSize: { md: "14px", xs: "14px" } }}>
            Services
          </Typography>
        </Box>

        <Typography
          variant="h3"
          sx={{
            fontSize: { lg: "50px", md: "40", sm: "30px", xs: "26px" },
            mt: 2,
            color: "#18191F",
            textAlign: "center",
          }}
        >
          Veiw <span style={{ color: "#73A812" }}>All Services</span>
        </Typography>
      </Box>
      <Box>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{
            display: { xs: "none", sm: "flex" },
            mt: { xl: 5, lg: 5, md: 5, sm: 3, xs: 2 },
            width: { sm: "90%", xs: "96%" },
            margin: "auto",
          }}
        >
          {cardData.map((data) => (
            <Grid item key={data.id} xs={12} sm={6} md={4} lg={3}>
              <Box>
                <CardMedia
                  component="img"
                  src={data.imageSrc}
                  alt="Image Alt Text"
                  width={290}
                  height={300}
                  sx={{
                    width: "100%",
                    height: "300px",
                    borderRadius: {
                      xs: "15px", // Small screens
                      sm: "15px", // Medium screens
                      md: "15px", // Large screens
                      lg: "15px", // Extra large screens
                      xl: "15px", // Larger screens
                    },
                  }}
                />
                <CardContent sx={{ padding: 0, mt: 1.2 }}>
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "24px",
                        lg: "22px",
                        md: "14px",
                        sm: "14px",
                        xs: "24px",
                      },
                      fontWeight: 500,
                      color: "#73A812",
                      padding: 0,
                    }}
                  >
                    {data.primaryText}
                  </Typography>
                  <Box style={{ display: "flex", gap: 15 }}>
                    <Typography
                      sx={{
                        fontSize: {
                          xl: "16px",
                          lg: "16px",
                          md: "13px",
                          sm: "10px",
                          xs: "15px",
                        },
                        color: "#00000099",
                      }}
                    >
                      {data.secondaryText1}
                    </Typography>
                  </Box>
                  <Box
                    component="a"
                    href={data.whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: "flex",
                      gap: "5px",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xl: "15px",
                          lg: "15px",
                          md: "14px",
                          sm: "14px",
                          xs: "15px",
                        },
                        fontWeight: 600,
                        color: "#73A812",
                        marginTop: "3px",
                        textDecoration: "none",
                      }}
                    >
                      {data.booking}
                    </Typography>
                    <EastIcon
                      sx={{
                        fontSize: "18px",
                        color: "#73A812",
                        marginTop: "5px",
                      }}
                    />
                  </Box>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          justifyContent="center"
          sx={{
            display: { xs: "flex", sm: "none" },
            mt: { xl: 5, lg: 5, md: 5, sm: 3, xs: 2 },
            width: { sm: "90%", xs: "96%" },
            margin: "auto",
          }}
        >
          {cardData.map((data) => (
            <Grid item key={data.id} xs={12} sm={6} md={4} lg={3}>
              <Box>
                <CardMedia
                  component="img"
                  src={data.imageSrc}
                  alt="Image Alt Text"
                  width={290}
                  height={300}
                  sx={{
                    width: "100%",
                    height: "300px",
                    borderRadius: {
                      xs: "15px", // Small screens
                      sm: "15px", // Medium screens
                      md: "15px", // Large screens
                      lg: "15px", // Extra large screens
                      xl: "15px", // Larger screens
                    },
                  }}
                />
                <CardContent sx={{ padding: 0, mt: 1.2 }}>
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "24px",
                        lg: "22px",
                        md: "14px",
                        sm: "14px",
                        xs: "24px",
                      },
                      fontWeight: 500,
                      color: "#73A812",
                      padding: 0,
                    }}
                  >
                    {data.primaryText}
                  </Typography>
                  <Box style={{ display: "flex", gap: 15 }}>
                    <Typography
                      sx={{
                        fontSize: {
                          xl: "16px",
                          lg: "16px",
                          md: "13px",
                          sm: "10px",
                          xs: "15px",
                        },
                        color: "#00000099",
                      }}
                    >
                      {data.secondaryText1}
                    </Typography>
                  </Box>
                  <Box
                    component="a"
                    href={data.whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: "flex",
                      gap: "5px",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xl: "15px",
                          lg: "15px",
                          md: "14px",
                          sm: "14px",
                          xs: "15px",
                        },
                        fontWeight: 600,
                        color: "#73A812",
                        marginTop: "3px",
                        textDecoration: "none",
                      }}
                    >
                      {data.booking}
                    </Typography>
                    <EastIcon
                      sx={{
                        fontSize: "18px",
                        color: "#73A812",
                        marginTop: "5px",
                      }}
                    />
                  </Box>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default AllService;
